import axios from "axios";

class addressBookService {
  async getList(params) {
    return await axios.get(`address-books`, { params });
  }

  async getItem(id) {
    return await axios.get(`address-books/${id}`);
  }

  async create(data) {
    return await axios.post(`address-books`, data);
  }

  async update(id, data) {
    return await axios.put(`address-books/${id}`, data);
  }

  async delete(id) {
    return await axios.delete(`address-books/${id}`);
  }

  async getContacts(id, params) {
    return await axios.get(`address-books/${id}/contacts`, { params });
  }

  async getContactsStatuses() {
    return await axios.get(`address-books/contacts/statuses`);
  }

  async importList(id, data) {
    return await axios.post(`address-books/${id}/import/list`, data);
  }

  async importCsv(id, data) {
    return await axios.post(`address-books/${id}/import/csv`, data);
  }
}

export default new addressBookService();
