import axios from "axios";
import store from "@/store";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      config.headers["Authorization"] = `Bearer ${user.accessToken}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (
      401 === error.response.status &&
      error.response.config.url !== "login"
    ) {
      store.dispatch("auth/logout");
      window.location = "/login";
      // } else if ([406, 422].includes(error.response.status)) {
      //   return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error.response.data);
    }
  }
);
