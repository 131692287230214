<template>
  <a class="dropdown-item" @click="switchTheme">
    <vue-feather :type="icon" />
    {{ text }}
  </a>
</template>

<script>
export default {
  name: "theme-switch",
  data: () => ({
    theme: "light",
  }),
  computed: {
    text() {
      return this.theme === "light" ? "Темная тема" : "Светлая тема";
    },
    icon() {
      return this.theme === "light" ? "moon" : "sun";
    },
    background() {
      return this.theme === "light" ? "#ffffff" : "#202b3b";
    },
    manifest() {
      return this.theme === "light" ? "manifest.json" : "manifest_dark.json";
    },
  },
  methods: {
    switchTheme() {
      this.theme = this.theme === "light" ? "dark" : "light";

      this.setTheme(this.theme);
    },
    setTheme(theme) {
      document.body.classList.remove("light");
      document.body.classList.remove("dark");
      document.body.classList.add(theme);

      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", this.background);

      document
        .querySelector('link[rel="manifest"]')
        .setAttribute("href", process.env.BASE_URL + this.manifest);

      localStorage.setItem("themeColor", theme);
    },
  },
  mounted() {
    this.theme = localStorage.getItem("themeColor")
      ? localStorage.getItem("themeColor")
      : "light";

    this.setTheme(this.theme);
  },
};
</script>

<style scoped></style>
