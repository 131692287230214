<template>
  <router-link
    class="nav-link"
    @click="$emit('menu-click')"
    :to="item.link"
    :class="{ 'router-link-active': isActiveRoute }"
  >
    <vue-feather :type="item.icon" />{{ item.title }}
  </router-link>
</template>

<script>
export default {
  name: "side-bar-item",
  props: ["item"],
  emits: ["menu-click"],
  computed: {
    isActiveRoute() {
      return this.$route.path.indexOf(this.item.link) === 0;
    },
  },
};
</script>

<style scoped>
.dropdown-icon {
  text-align: right !important;
  margin-right: 0 !important;
}
</style>
