import Sidebar from "primevue/sidebar";
import ValidateError from "@/components/dumb-components/validateError";

export default () => ({
  name: "formMixin",
  components: { Sidebar, ValidateError },
  computed: {},
  methods: {
    getInputClass(field) {
      return field.$invalid && this.submitted ? "invalid" : "";
    },
    closeSidebar() {
      this.showSidebar = false;
      this.$emit("clickClose", false);
    },
  },
  beforeUpdate() {
    this.showSidebar = this.display;
  },
});
