<template>
  <div class="page-container">
    <div class="page-header">
      <NavBar />
    </div>
    <SideBar @menu-click="toggleSidebar" />
    <div class="page-content">
      <div class="main-wrapper">
        <div v-if="breadcrumbs" class="row">
          <div class="col">
            <div class="card">
              <breadcrumb :home="home" :model="breadcrumbs" />
            </div>
          </div>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "primevue/breadcrumb";
import NavBar from "@/components/NavBar";
import SideBar from "@/components/SideBar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MainLayout",
  props: ["breadcrumbs"],
  components: {
    SideBar,
    NavBar,
    Breadcrumb,
  },
  data: () => ({
    home: { icon: "pi pi-desktop", to: "/" },
  }),
  methods: {
    ...mapActions({
      getClientFilter: "client/getFilterList",
      getGates: "message/getGates",
      getMe: "auth/me",
    }),
    toggleSidebar() {
      if (this.isMobile) {
        document.body.classList.toggle("sidebar-hidden");
      }
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      isAdmin: "auth/isAdmin",
      user: "auth/user",
      me: "auth/me",
      gates: "message/gates",
    }),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    if (this.isAdmin) {
      this.getClientFilter();
      this.getGates();
      this.getMe();
    } else {
      this.getMe();
    }
  },
};
</script>
