<template>
  <Sidebar
    v-model:visible="this.showSidebar"
    :dismissable="false"
    :showCloseIcon="false"
    position="right"
    class="p-sidebar-md"
    @keydown.esc="this.closeSidebar"
  >
    <h5 class="card-title">Добавление рассылки</h5>
    <form @submit.prevent="handleSubmit(!v$.$invalid)" novalidate>
      <div class="row">
        <div class="mb-3">
          <label for="name" class="form-label">Название</label>
          <input
            autocomplete="off"
            autofocus="autofocus"
            type="text"
            class="form-control"
            :class="this.getInputClass(v$.model.name)"
            v-model="model.name"
            id="name"
          />

          <validate-error
            :field="v$.model.name"
            name="Текст"
            :submitted="submitted"
          />
        </div>
        <div class="mb-3">
          <label :for="model.senderAlias" class="form-label">
            Отправитель (alias)
          </label>
          <select
            v-model="model.senderAlias"
            :class="this.getInputClass(v$.model.senderAlias)"
            class="form-select"
          >
            <option
              v-for="sender in senderAliasList.list"
              :key="sender.id"
              :value="sender.id"
            >
              {{ sender.name }}
            </option>
          </select>
          <validate-error
            :field="v$.model.senderAlias"
            name="Текст"
            :submitted="submitted"
          />
        </div>
        <div class="mb-3">
          <label :for="model.addressBook" class="form-label">
            Адресная книга
          </label>
          <select
            v-model="model.addressBook"
            :class="this.getInputClass(v$.model.addressBook)"
            class="form-select"
          >
            <option
              v-for="book in addressBookList.list"
              :key="book.id"
              :value="book.id"
            >
              {{ book.name }}
            </option>
          </select>
          <validate-error
            :field="v$.model.addressBook"
            name="Текст"
            :submitted="submitted"
          />
        </div>
        <div class="mb-3">
          <label :for="template" class="form-label">Шаблон</label>
          <select v-model="template" @change="setTemplate" class="form-select">
            <option></option>
            <option
              v-for="template in templateList.list"
              :key="template.id"
              :value="template.id"
            >
              {{ template.name }}
            </option>
          </select>
        </div>

        <div class="mb-3">
          <label :for="model.text" class="form-label">Текст</label>
          <textarea
            rows="6"
            maxlength="1000"
            class="form-control"
            :class="this.getInputClass(v$.model.text)"
            :id="model.text"
            autocomplete="off"
            v-model="model.text"
          ></textarea>
          <rock-sms-info-badge
            :text="model.text"
            @count="(n) => (this.smsCount = n)"
          />

          <validate-error
            :field="v$.model.text"
            name="Текст"
            :submitted="submitted"
          />
        </div>

        <div class="mb-3">
          <label :for="model.dateStart" class="form-label"
            >Начало рассылки</label
          >
          <date-picker
            :class="this.getInputClass(v$.model.text)"
            v-model="model.dateStart"
            mode="dateTime"
            :valid-hours="{ min: 9, max: 19 }"
            :minute-increment="10"
            title-position="left"
            :min-date="new Date()"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </date-picker>

          <validate-error
            :field="v$.model.dateStart"
            name="Текст"
            :submitted="submitted"
          />
        </div>

        <div class="mb-3 cost-info">
          <div class="card bg-secondary">
            <div class="card-body">
              <ul class="list-unstyled profile-about-list">
                <li>
                  <i class="pi pi-users m-r-xxs"></i>
                  <span>
                    Номеров: <span class="info">{{ contactsCount }}</span>
                  </span>
                </li>
                <li>
                  <i class="pi pi-dollar m-r-xxs"></i>
                  <span
                    >Стоимость:
                    <span class="info">{{ getAmount(cost) }}</span>
                  </span>
                </li>
                <li>
                  <i class="pi pi-wallet m-r-xxs"></i>
                  <span>
                    Баланс: <span class="info">{{ getAmount(balance) }}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <button
            type="button"
            class="btn btn-secondary m-b-xs m-r-xs"
            data-bs-dismiss="modal"
            @click="closeSidebar"
          >
            Закрыть
          </button>

          <rock-submit-button
            :processing="this.processing"
            @click="handleSubmit(!v$.$invalid)"
          />
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DatePicker } from "v-calendar";
import RockSmsInfoBadge from "../dumb-components/rockSmsInfoBadge";
import { useVuelidate } from "@vuelidate/core/dist/index.esm";
import { required, maxLength, minLength } from "@/utils/i18n-validators";
import formMixin from "@/mixins/formMixin";
import crudService from "@/services/crudService";
import RockSubmitButton from "../dumb-components/rockSubmitButton";

export default {
  name: "campaign-form",
  props: ["id", "display"],
  mixins: [formMixin()],
  components: { RockSubmitButton, RockSmsInfoBadge, DatePicker },
  setup: () => ({ v$: useVuelidate({ $autoDirty: true }) }),
  data() {
    return {
      submitted: false,
      processing: false,
      formAction: "",
      showSidebar: this.display,
      vuelidateExternalResults: [],
      template: "",
      contactsCount: 0,
      smsCount: 1,
      masks: {
        input: "DD.MM.YYYY",
      },
      model: {
        name: "",
        senderAlias: null,
        addressBook: null,
        text: "",
        dateStart: new Date(),
        status: 1,
      },
    };
  },
  validations() {
    return {
      model: {
        name: { required, maxLength: maxLength(50) },
        senderAlias: { required },
        addressBook: { required },
        text: { required, minLength: minLength(3) },
        dateStart: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      senderAliasList: "senderAlias/list",
      addressBookList: "addressBook/list",
      templateList: "template/list",
      balance: "auth/balance",
    }),
    cost() {
      return this.contactsCount * this.smsCount * 0.34;
    },
  },
  methods: {
    ...mapActions({
      getSenderAliasList: "senderAlias/getList",
      getAddressBookList: "addressBook/getList",
      getTemplateList: "template/getList",
      updateCampaign: "campaign/update",
      setTemplate() {
        const template = this.templateList.list.filter(
          (item) => item.id === this.template
        );
        this.model.text = template[0].text;
      },
    }),
    getAmount(amount) {
      return new Intl.NumberFormat("ru-RU").format(amount);
    },
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.processing = true;

      if (this.formAction === "update") {
        this.updateCampaign({ id: this.campaign.id, data: this.model }).then(
          () => {
            this.$emit("refreshList");
          }
        );
      } else {
        crudService.create("campaigns", this.model).then(
          () => {
            this.submitted = false;
            this.processing = false;

            this.$toast.add({
              severity: "success",
              summary: "Успех!",
              detail: "Рассылка была успешно создана и отправлена на модерацию",
              life: 3000,
            });

            this.$emit("refreshList");

            this.closeSidebar();
          },
          (fail) => {
            Object.assign(this.vuelidateExternalResults, {
              model: fail.errors,
            });
            this.processing = false;
          }
        );
      }
    },
  },
  watch: {
    "model.addressBook": function (newVal) {
      const addressBook = this.addressBookList.list.filter(
        (item) => item.id === newVal
      );

      this.contactsCount = addressBook[0].contactsCount;
    },
  },
  beforeUpdate() {
    this.showSidebar = this.display;
    this.formAction = "update";

    if (this.id) {
      // this.getTemplate(this.id).then(() => {
      //   this.model.name = this.template.name;
      //   this.model.text = this.template.text;
      // });
    } else {
      this.model.name = null;
      this.model.text = null;
      this.formAction = "create";
    }

    this.getSenderAliasList({ status: 1 }).then(() => {
      if (this.senderAliasList.list.length === 1) {
        this.model.senderAlias = this.senderAliasList.list[0].id;
      }
    });

    this.getAddressBookList().then(() => {
      if (this.addressBookList.list.length === 1) {
        this.model.getAddressBoaddressBookokList =
          this.addressBookList.list[0].id;
      }
    });

    this.getTemplateList();

    this.model.name = "Рассылка от " + this.$moment().format("DD.MM.YYYY");
  },
};
</script>

<style scoped>
.cost-info .card-body {
  padding: 10px 20px;
}
.cost-info .card-body li {
  margin-bottom: 0;
}
.invalid-feedback {
  display: inherit;
}
</style>
