import crudService from "../services/crudService";

const path = "templates";

export const template = {
  namespaced: true,
  state: {
    list: [],
    item: null,
  },
  actions: {
    async getList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_LIST", response.data);
      });
    },
    async getItem({ commit }, id) {
      await crudService.getOne(path, id).then((response) => {
        commit("SET_ITEM", response.data);
      });
    },
    async delete({ commit }, id) {
      await crudService.delete(path, id).then((response) => {
        if (response.status === 204) {
          commit("DELETE_ITEM", id);
        }
      });
    },
  },
  mutations: {
    SET_LIST: (state, list) => {
      state.list = list;
    },
    SET_ITEM: (state, item) => {
      state.item = item;
    },
    DELETE_ITEM: (state, id) => {
      state.list.list = state.list.list.filter((element) => element.id !== id);
    },
  },
  getters: {
    list: (state) => state.list,
    item: (state) => state.item,
  },
};
