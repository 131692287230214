import axios from "axios";

class crudService {
  async getList(path, params) {
    return await axios.get(path, { params });
  }

  async get(path) {
    return await axios.get(`${path}`);
  }

  async getOne(path, id) {
    return await axios.get(`${path}/${id}`);
  }

  async getOneAction(path, id, action) {
    return await axios.get(`${path}/${id}/${action}`);
  }

  async create(path, data) {
    return await axios.post(path, data);
  }

  async update(path, id, data) {
    return await axios.put(`${path}/${id}`, data);
  }

  async updateSelf(path, data) {
    return await axios.put(`${path}`, data);
  }

  async delete(path, id) {
    return await axios.delete(`${path}/${id}`);
  }

  async getStatusList(path) {
    return await axios.get(`${path}/statuses`);
  }

  async getFilterList(path) {
    return await axios.get(`${path}/all`);
  }
}

export default new crudService();
