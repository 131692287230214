<template>
  <div class="container">
    <div class="error-container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorLayout",

  mounted() {
    document.body.classList.add("error-page", "err-404");
  },
  unmounted() {
    document.body.classList.remove("error-page", "err-404");
  },
};
</script>

<style>
#app {
  position: relative;
  height: 100%;
}
</style>
