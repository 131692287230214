import axios from "axios";

class messageService {
  async getList(params) {
    return await axios.get(`messages`, { params });
  }

  async getStatusList() {
    return await axios.get(`messages/statuses`);
  }

  async getItem(id) {
    return await axios.get(`messages/${id}`);
  }

  async send(data) {
    return await axios.post(`messages/send`, data);
  }

  async update(id, data) {
    return await axios.put(`messages/${id}`, data);
  }

  async getStatuses() {
    return await axios.get(`messages/statuses`);
  }

  async getGates() {
    return await axios.get(`messages/gates`);
  }

  async getStats() {
    return await axios.get(`messages/stats`);
  }

  async getChart(params) {
    return await axios.get(`messages/chart`, { params });
  }
}

export default new messageService();
