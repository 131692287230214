import * as validators from "@vuelidate/validators";
import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler";
import { helpers } from "@vuelidate/validators";

const { createI18nMessage } = validators;

const i18n = createI18n({
  locale: "ru",
  messages: {
    ru: require("@/message/ru.json"),
    ro: require("@/message/ro.json"),
  },
});

const withI18nMessage = createI18nMessage({
  t: i18n.global.t.bind(i18n),
});

export const phone = withI18nMessage(
  helpers.regex(/(60|61|62|67|68|69|76|78|79)([0-9]{6})/gm)
);
export const alias = withI18nMessage(helpers.regex(/^[a-zA-Z1-9 _-]*$/i));
export const required = withI18nMessage(validators.required);
export const email = withI18nMessage(validators.email);
export const numeric = withI18nMessage(validators.numeric);
export const alphaNum = withI18nMessage(validators.alphaNum);
export const minValue = withI18nMessage(validators.minValue, {
  withArguments: true,
});
export const minLength = withI18nMessage(validators.minLength, {
  withArguments: true,
});
export const maxLength = withI18nMessage(validators.maxLength, {
  withArguments: true,
});
export const sameAs = withI18nMessage(validators.sameAs, {
  withArguments: true,
});
export const between = withI18nMessage(validators.between, {
  withArguments: true,
});
