import addressBookService from "../services/addressBookService";
import crudService from "../services/crudService";

const path = "address-books";

export const addressBook = {
  namespaced: true,
  state: {
    item: null,
    list: [],
    contacts: [],
    selected: [],
    contacts_statuses: [],
  },
  actions: {
    async getList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_LIST", response.data);
      });
    },
    async getItem({ commit }, id) {
      await addressBookService.getItem(id).then((response) => {
        commit("SET_ITEM", response.data);
      });
    },
    async getContacts({ commit }, data) {
      await addressBookService
        .getContacts(data.id, data.params)
        .then((response) => {
          commit("SET_CONTACTS", response.data);
        });
    },

    async deleteContact({ commit }, id) {
      await crudService.delete("contacts", id).then((response) => {
        if (response.status === 204) {
          commit("DELETE_CONTACT", id);
        }
      });
    },

    async getContactsStatuses({ commit }) {
      await addressBookService.getContactsStatuses().then((response) => {
        commit("SET_CONTACTS_STATUS_LIST", response.data);
      });
    },
    setActive({ commit }, data) {
      commit("SET_ACTIVE", data);
    },
  },
  mutations: {
    SET_LIST: (state, list) => {
      state.list = list;
    },
    SET_ITEM: (state, item) => {
      state.item = item;
    },
    SET_CONTACTS: (state, list) => {
      state.contacts = list;
    },
    DELETE_CONTACT: (state, id) => {
      state.contacts.list = state.contacts.list.filter(
        (element) => element.id !== id
      );
    },
    SET_CONTACTS_STATUS_LIST: (state, item) => {
      state.contacts_statuses = item;
    },
    SET_ACTIVE: (state, item) => {
      state.active = item;
    },
  },
  getters: {
    item: (state) => state.item,
    list: (state) => state.list,
    contacts: (state) => state.contacts,
    contacts_statuses: (state) => state.contacts_statuses,
    active: (state) => state.active,
  },
};
