import { createStore } from "vuex";
import { auth } from "@/store/auth.module";
import { message } from "@/store/message.module";
import { campaign } from "@/store/campaign.module";
import { addressBook } from "@/store/address-book.module";
import { client } from "@/store/client.module";
import { template } from "@/store/template.module";
import { senderAlias } from "@/store/sender-alias.module";
import { invoice } from "./invoice.module";
import { payment } from "./payment.module";

export default createStore({
  modules: {
    auth,
    message,
    campaign,
    addressBook,
    client,
    template,
    invoice,
    payment,
    senderAlias,
  },
  getters: {
    isAuthenticated(state) {
      return state.auth.status.loggedIn;
    },
  },
});
