import campaignService from "@/services/campaignService";

export const campaign = {
  namespaced: true,
  state: {
    list: [],
    item: null,
    status_list: [],
  },
  actions: {
    async getList({ commit }, params) {
      await campaignService.getList(params).then((response) => {
        commit("SET_LIST", response.data);
      });
    },
    async getStatusList({ commit }) {
      await campaignService.getStatusList().then((response) => {
        commit("SET_STATUS_LIST", response.data);
      });
    },
    async getItem({ commit }, id) {
      await campaignService.getItem(id).then((response) => {
        commit("SET_ITEM", response.data);
      });
    },
    async accept({ commit }, id) {
      await campaignService.accept(id).then((response) => {
        commit("SET_ITEM", response.data);
      });
    },
    async remove({ commit }) {
      commit("SET_ITEM", null);
    },
  },
  mutations: {
    SET_LIST: (state, list) => {
      state.list = list;
    },
    SET_ITEM: (state, item) => {
      state.item = item;
    },
    SET_STATUS_LIST: (state, list) => {
      state.status_list = list;
    },
  },
  getters: {
    list: (state) => state.list,
    item: (state) => state.item,
    status_list: (state) => state.status_list,
  },
};
