import axios from "axios";

class campaignService {
  async getList(params) {
    return await axios.get(`campaigns`, { params });
  }

  async getItem(id) {
    return await axios.get(`campaigns/${id}`);
  }

  async create(data) {
    return await axios.post(`campaigns`, data);
  }

  async update(id, data) {
    return await axios.put(`campaigns/${id}`, data);
  }

  async accept(id) {
    return await axios.put(`campaigns/${id}/accept`);
  }

  async getStatusList() {
    return await axios.get(`campaigns/statuses`);
  }
}

export default new campaignService();
