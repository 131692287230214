import messageService from "@/services/messageService";

export const message = {
  namespaced: true,
  state: {
    list: [],
    status_list: [],
    item: null,
    stats: [],
    chart: [],
    gates: [],
  },
  actions: {
    async getList({ commit }, params) {
      await messageService.getList(params).then((response) => {
        commit("SET_LIST", response.data);
      });
    },
    async getStatusList({ commit }) {
      await messageService.getStatusList().then((response) => {
        commit("SET_STATUS_LIST", response.data);
      });
    },
    async getItem({ commit }, id) {
      await messageService.getItem(id).then((response) => {
        commit("SET_ITEM", response.data);
      });
    },
    async getStats({ commit }) {
      await messageService.getStats().then((response) => {
        commit("SET_STATS", response.data);
      });
    },
    async getChart({ commit }, params) {
      await messageService.getChart(params).then((response) => {
        commit("SET_CHART", response.data);
      });
    },
    async getGates({ commit }) {
      await messageService.getGates().then((response) => {
        commit("SET_GATES", response.data);
      });
    },
    async update({ commit }, params) {
      await messageService.update(params.id, params.data).then((response) => {
        commit("SET_ITEM", response.data);
      });
    },
    async remove({ commit }) {
      commit("SET_ITEM", null);
    },
  },
  mutations: {
    SET_LIST: (state, list) => {
      state.list = list;
    },
    SET_STATUS_LIST: (state, list) => {
      state.status_list = list;
    },
    SET_ITEM: (state, item) => {
      state.item = item;
    },
    SET_STATS: (state, item) => {
      state.stats = item;
    },
    SET_CHART: (state, item) => {
      state.chart = item;
    },
    SET_GATES: (state, item) => {
      state.gates = item;
    },
  },
  getters: {
    list: (state) => state.list,
    status_list: (state) => state.status_list,
    item: (state) => state.item,
    stats: (state) => state.stats,
    chart: (state) => state.chart,
    gates: (state) => state.gates,
  },
};
