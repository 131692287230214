<template>
  <div
    v-if="(field.$invalid && submitted) || field.$pending.$response"
    class="invalid-feedback"
  >
    <span v-for="(error, index) of field.$silentErrors" :key="index">
      <template v-if="index === 0">
        {{ error.$message }}
      </template>
    </span>
  </div>
</template>

<script>
export default {
  name: "validate-error",
  props: ["field", "name", "submitted"],
};
</script>

<style scoped>
.invalid-feedback {
  display: inherit;
}
</style>
