<template>
  <nav class="navbar navbar-expand-lg d-flex justify-content-between">
    <div class="" id="navbarNav">
      <ul class="navbar-nav" id="leftNav">
        <li class="nav-item">
          <a
            class="nav-link"
            id="sidebar-toggle"
            @click="toggleSidebar"
            href="#"
          >
            <vue-feather type="menu" />
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="$router.go(-1)" href="#">
            <vue-feather type="arrow-left" />
          </a>
        </li>
        <li v-if="!isAdmin" class="nav-item">
          <a
            @click="this.showMessageForm = true"
            href="#"
            class="nav-link"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            <vue-feather type="message-square" size="20" />
            {{ $t("COMMON.send_sms") }}
          </a>
        </li>
        <li v-if="!isAdmin" class="nav-item">
          <a
            @click="this.showCampaignForm = true"
            href="#"
            class="nav-link"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            <vue-feather type="activity" size="20" />
            {{ $t("COMMON.create_campaign") }}
          </a>
        </li>
      </ul>
    </div>

    <div class="logo">
      <router-link class="navbar-brand" to="/">
        <img :src="require('@/assets/images/logo-beta.svg')" />
      </router-link>
    </div>

    <div class="" id="headerNav">
      <ul class="navbar-nav">
        <!--        <lang-switch />-->

        <li class="nav-item nav-item-balance">
          <router-link class="balance" :to="{ name: 'payment' }">
            <span class="pi pi-wallet m-r-xxs"></span>
            {{ this.balance }} лей
          </router-link>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link profile-dropdown"
            href="#"
            id="profileDropDown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img v-if="this.avatar" :src="this.avatar" alt="" />
            <img
              v-else
              :src="require('@/assets/images/avatars/profile-image.png')"
            />
          </a>

          <div
            class="dropdown-menu dropdown-menu-end profile-drop-menu animate slideIn"
            aria-labelledby="profileDropDown"
          >
            <theme-switch />
            <router-link class="dropdown-item" to="/profile">
              <vue-feather type="user" />
              Мой профиль
            </router-link>
            <router-link class="dropdown-item" to="/settings">
              <vue-feather type="settings" />
              Настройки
            </router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click.prevent="logOut">
              <vue-feather type="log-out" />
              Выйти
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>

  <message-form
    :id="this.formId"
    :display="this.showMessageForm"
    @clickClose="this.showMessageForm = false"
  />
  <campaign-form
    :id="this.formId"
    :display="this.showCampaignForm"
    @clickClose="this.showCampaignForm = false"
  />
</template>

<script>
import { mapGetters } from "vuex";
// import LangSwitch from "./langSwitch";
import CampaignForm from "./form/CampaignForm";
import MessageForm from "./form/MessageForm";
import ThemeSwitch from "./themeSwitch";

export default {
  name: "NavBar",
  components: {
    ThemeSwitch,
    MessageForm,
    CampaignForm,
    // LangSwitch
  },
  data: () => ({
    formId: null,
    showCampaignForm: false,
    showMessageForm: false,
  }),
  methods: {
    logOut() {
      let impersonatedUser = localStorage.getItem("impersonatedUser");

      this.$store.dispatch("auth/logout");

      if (impersonatedUser) {
        this.$router.push(0);
      } else {
        this.$router.push("/login");
      }
    },
    toggleSidebar() {
      document.body.classList.toggle("sidebar-hidden");

      localStorage.setItem(
        "sidebar",
        this.isSidebarClosed ? "opened" : "closed"
      );
    },
    closeForm() {
      this.showCampaignForm = false;
      this.showMessageForm = false;
    },
  },
  computed: {
    ...mapGetters({
      avatar: "auth/avatar",
      balance: "auth/balance",
      isAdmin: "auth/isAdmin",
    }),
    isSidebarClosed() {
      return localStorage.getItem("sidebar") === "closed" && !this.isMobile;
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.isSidebarClosed) {
      document.body.classList.add("sidebar-hidden");
    }
  },
};
</script>

<style lang="scss" scoped>
#headerNav > ul > li > a.profile-dropdown img {
  border-radius: 30px;
}

a.dropdown-item {
  cursor: pointer;
}
</style>
