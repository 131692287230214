import crudService from "../services/crudService";

const path = "sender-aliases";

export const senderAlias = {
  namespaced: true,
  state: {
    list: [],
    item: null,
    status_list: [],
  },
  actions: {
    async getList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_LIST", response.data);
      });
    },
    async getItem({ commit }, id) {
      await crudService.getOne(path, id).then((response) => {
        commit("SET_ITEM", response.data);
      });
    },
    async create({ commit }, request) {
      return new Promise((resolve) => {
        crudService.create(path, request.data).then((response) => {
          commit("SET_ITEM", response.data);
          resolve(response);
        });
      });
    },
    async update({ commit }, request) {
      await crudService
        .update(path, request.id, request.data)
        .then((response) => {
          commit("SET_ITEM", response.data);
        });
    },
    async delete({ commit }, id) {
      await crudService.delete(path, id).then((response) => {
        if (response.status === 204) {
          commit("DELETE_ITEM", id);
        }
      });
    },
    async getStatusList({ commit }) {
      await crudService.getStatusList(path).then((response) => {
        commit("SET_STATUS_LIST", response.data);
      });
    },
  },
  mutations: {
    SET_LIST: (state, list) => {
      state.list = list;
    },
    SET_ITEM: (state, item) => {
      state.item = item;
    },
    DELETE_ITEM: (state, id) => {
      state.list.list = state.list.list.filter((element) => element.id !== id);
    },
    SET_STATUS_LIST: (state, list) => {
      state.status_list = list;
    },
  },
  getters: {
    list: (state) => state.list,
    item: (state) => state.item,
    status_list: (state) => state.status_list,
  },
};
