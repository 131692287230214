<template>
  <component :is="layout" :breadcrumbs="breadcrumbs">
    <router-view />
  </component>
  <Toast />
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import AuthLayout from "@/layouts/AuthLayout";
import ErrorLayout from "@/layouts/ErrorLayout";
import Toast from "primevue/toast";

export default {
  name: "App",
  components: {
    MainLayout,
    AuthLayout,
    ErrorLayout,
    Toast,
  },

  computed: {
    layout() {
      return this.$route.meta.layout + "-layout";
    },
    breadcrumbs() {
      return false;
    },
  },
};
</script>
