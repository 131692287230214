import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { layout: "auth", auth: false },
    component: () => import("@/views/auth/Login"),
  },
  {
    path: "/register",
    name: "register",
    meta: { layout: "auth", auth: false },
    component: () => import("@/views/auth/Register"),
  },

  {
    path: "/",
    name: "dashboard",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/Dashboard"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/auth/Profile"),
  },
  {
    path: "/message",
    name: "message",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/Message"),
  },
  {
    path: "/campaign/:id",
    name: "campaign-detail",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/campaign/CampaignDetail"),
  },
  {
    path: "/campaign",
    name: "campaign",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/campaign/CampaignList"),
  },

  {
    path: "/address-book/:id",
    name: "address-book-detail",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/AddressBook"),
  },
  {
    path: "/address-book",
    name: "address-book",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/AddressBook"),
  },

  {
    path: "/client/:id/contract",
    name: "client-contract",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/client/ClientContract"),
  },
  {
    path: "/client/:id",
    name: "client-detail",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/client/ClientDetail"),
  },
  {
    path: "/client",
    name: "client",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/client/ClientList"),
  },

  {
    path: "/template",
    name: "template",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/Template"),
  },

  {
    path: "/sender-alias",
    name: "senderAlias",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/SenderAlias"),
  },

  {
    path: "/invoice",
    name: "invoice",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/Invoice"),
  },

  {
    path: "/payment",
    name: "payment",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/Payment"),
  },

  {
    path: "/settings/api",
    name: "settings-api",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/settings/SettingsApi"),
  },
  {
    path: "/settings/otp",
    name: "settings-otp",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/settings/SettingsOtp"),
  },
  {
    path: "/settings/bitrix",
    name: "settings-bitrix",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/settings/SettingsBitrix"),
  },
  {
    path: "/settings/amo",
    name: "settings-amo",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/settings/SettingsAmo"),
  },
  {
    path: "/settings/altegio",
    name: "settings-altegio",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/settings/SettingsAltegio"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/settings/SettingsProfile"),
  },

  {
    path: "/knowledge-base",
    name: "knowledgeBase",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/KnowledgeBase"),
  },

  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: { layout: "error", auth: false },
    component: () => import("@/views/404"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
